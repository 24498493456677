import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackToTop from "../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const PaymentOptions = ({ location }) => {
  // console.log(location)
  return (
    <Layout>
      <Seo title="Payment Options" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero noVideoInner">
              <h1>Payment Options</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about-wrapper py-5"> */}
      <Container className="acl-container my-5">
        <Row className="border-none">
          <Col md={12}>
            <p>
              Colorado Canine Orthopedics accepts Visa, MasterCard, American Express
              and Discover credit cards, as well as cash and personal checks.
            </p>
            <h3>Financing Options</h3>
            <p>
              <strong>Flexible Financing with the CareCredit credit card</strong>
            </p>
            <p>CareCredit gives you a convenient, flexible way to pay for care for the whole family—including pets! With no interest if paid in full within 6 months on qualifying purchases of $200 or more, you can pay over time with convenient monthly payments.* Interest will be charged to your account from the purchase date if the promotional balance is not paid in full within the promotional period. Minimum monthly payments required.</p>
            <p>See if you prequalify with no impact to your credit score.</p>
            <a
              href="https://www.carecredit.com/go/777RMH/"
              target="_blank"
              className="tel-cta"
              rel="noreferrer"
            >
              <LazyLoadImage
                effect="blur"
                src="/assets/img/carecredit_button_applynow.png"
                alt="WFHA"
                className="img-fluid mb-4"
              />
            </a>
            <p><small>*No interest will be charged on the promo balance if you pay it off, in full, within the promo period. If you do not, interest will be charged on the promo balance from the purchase date. The required minimum monthly payments may or may not pay off the promo balance before the end of the promo period, depending on purchase amount, promo length and payment allocation. Regular account terms apply to non-promo purchases and, after promo period ends, to the promo balance. For new accounts: Purchase APR (interest rate) is 29.99%; Minimum Interest Charge is $2. Existing cardholders: See your credit card agreement terms. Subject to credit approval.</small></p>
          </Col>
        </Row>
      </Container>
      <BackToTop />
      {/* </div> */}
    </Layout>
  )
}

export default PaymentOptions
